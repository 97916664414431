/*
	Only for client customization not for core development cycle.
    Created on : 12-Feb-2018, 2:24:32 PM
    Author     : James Hansen <jhansen@publivate.com>
*/

@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../node_modules/bootstrap/scss/utilities/_spacing.scss';

@import "print.css";

html, body, #wrapper {
  min-height: 100vh;
}

#wrapper {
  display: flex;
  flex-direction: column;
}

body main {
  // Fixes Dropdown menu hidden 
  padding-bottom: 4rem;
  z-index: 0;
  flex-grow: 1;
}


a {
  color: $blue;

  &.back-link {
    padding-left: 0;
  }
}

// Correct form iframe for full width
.view-form {
  iframe {
    display: block;
    min-height: calc(100vh - 70px);
  }
  main {
    margin-top: 0;
    padding-bottom: 0;
    & > .container {
      padding: 0;
      & > .row {
        margin: 0;
        & > div {
          padding: 0;
          & > .row {
            margin: 0;
            & > div {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .container {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

#pv-footer {
  background-color: $blue;
  padding: 3rem 3rem 0 0rem;
  height: 10vh;
}

// homepage css
label {
  font-size: small;
  font-weight: bold;
  padding: $spacer/4 $spacer/2;
}

header#pv-header {
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 3px 8px #00000029;
  padding: 0 2.5em;
  width: 100%;
  #pv-skip-to {
    padding-top: 0 !important;
  }
}

#pv-main-top {

}

.btn {
  font-weight: bold;
}

.client-btn-more {
  padding: 0;
  margin-right: 1em;
}

.navbar-brand img {
  max-height: 50px;
}

.nav-item {

  .nav-header,
  a {
    font-weight: bold !important;
    text-transform: uppercase;
  }
}

.cb-login-module {
  h1 {
    text-align: center;
    margin-bottom: 0;
  }
  @include media-breakpoint-down(sm) {
    h1 {
      font-size: 1rem;
    }

    img {
      display: none;
    }
  }

  }

.cb-login-module .cbLoginForm,
#pv-main-top .cbLoginForm {
  margin: 0 auto 10px !important;

  .pretext {
    text-align: center;
    p {
      margin-bottom: 0;
    }

    img {
      max-width: 175px;
    }
  }

  #form-login-forgot {
    margin-bottom: 0;
  }

  .form-group {
    background: #fff;
    border: 1px solid $blue;
    margin-bottom: 0;

    button {
      height: 50px
    }

    button,
    input {
      border: none;
      border-radius: 0;
      box-shadow: none;
    }
  }
}

#pv-main-top .moduletable-bg {
  width: 100%;
}

#pv-main-top .moduletable {
  margin-left: auto;
  margin-right: auto;

}

// Client Registration/ Admin Registion
.cbFieldsContentsTab,
#clientForm {
  .form-group {
    border: $border-width solid $blue;
    margin-bottom: 0;

    label {
      display: inline-block;
      font-size: small;
      font-weight: lighter;
      padding-left: $spacer/2 !important;
    }

    select,
    input {
      border-radius: 0;
      border-color: transparent;
      margin: 0;
      box-shadow: none;

      &.is-invalid {
        border-color: #f4728f;
      }
    ;
    }
  }

}

// Admin Registration
.cbRegistration {
  width: 50%;
  margin: 0 auto;

  .cbFieldsContentsTab {
    display: flex;
    margin: 0 0;

    .form-group {
      background: #fff;
      flex: 0 0 100%;
      margin-bottom: 0;

      label {
        background: #fff;
      }

      .form-control {
        width: 100%;
      }
    }

  }

  .cbIconsBottom,
  .cbIconsTop {
    width: 100%;
  }

  .cbRegistrationTitle {
    text-align: center;
    border: none;
  }

  .cbRegistrationButtons {

  }

  .cbRegistrationSubmit {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    height: 70px !important;
  }

  #cbfr_50 {
    input {
      border: 1px solid $blue !important;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

    }
  }

  #cbfv_50 {
    display: flex;
    padding: 0.5rem 2rem;

    [class*="col-"] {
      padding-left: 0;
      padding-right: 0;
    }

    [class*="col-"]:last-child {
      background: $blue;
      color: $white;
      padding: 6px 12px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  #cbtf_11 {
    display: block;
  }

  #cbfr_46,
  #cbfr_48 {
    flex: 0 0 50%
  }

  .hide-email {
  }

  #email {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .cbIconsBottom {
    border: none;
  }
}

// Forms List
.amex-list {

  .btn-link {
    font-weight: 800;
    letter-spacing: 2px;
  }

  .amex-row {
    @extend .mb-3;

    .header-row {
      border-bottom: 1px solid $blue;
    }

    .form-card {
      border: 1px solid $blue;
      min-height: 253px;
      border-radius: 5px;
      font-weight: bold;
      @extend .mt-3;

      &.create-form-card {
        min-height: 253px;
        border: 2px dashed $blue;

        a:first-child.btn {
          margin-bottom: 1.5rem;
        }
      }

      .btn-create {
        //height: 50px;
      }

      &-header {
        padding: 1rem 1rem 1rem;
        .company {
          padding: 0.375rem 0;
        }
      }

      &-stats {

        .table {
          margin: 0;

          td {
            font-weight: bold;
          }

          thead {
            background: $blue;

            .icon {
              color: $white;
            }
          }

          td:last-child {
            text-align: center;
          }

          .col-totals {
            background: #F2F2F2;
            border-top: 2px solid #333;
          }

          .row-total {
            border-left: 1px solid #333;
          }
        }
      }
    }
  }
}

.btn.ellipsis {
  letter-spacing: 3px;
  font-weight: 900;
  font-size: 130%;
  line-height: 24px;
  height: 24px;
}

// Amex Dashboard
.amex-dashboard {
  img {
    height: 170px;
    @include media-breakpoint-down(sm){
      display: none;
    }
  }

  .list-group {
    .list-group-item {
      padding: 0;
      @extend .my-2;
    }

    li {
      border-color: $blue;
      border-radius: 5px !important;

      a {
        padding: .75rem 1.25rem;
        font-weight: bold;
        display: inline-block;
        width: 100%;
      }
    }
  }
}

// Amex Admin Forms
.amex-admin-forms {
  h1 {
    text-align: center;
  }
}

.admin-listusers {
  table {
    tbody {
      tr:nth-of-type(odd) {
        background-color: #fff !important;
      }

      tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, .05);
      }

    }

  }
}

.mfp-content {
  h1 {
    font-weight: bold !important;
  }
}

.table td {
  padding: .8rem .75rem .7rem .75rem;
  vertical-align: middle;
}

#wb-rsz {
  position: absolute;
  bottom: 0;
}

.top-spacer {
  margin-top: 4rem;
}

.btn-link.ellipsis:hover {
  text-decoration: none;
  & > span {
    color: black;
    text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff;
  }
}

#pv-header {
  position: relative;
  z-index: 1;
}

.btn, .btn-lg, .btn-md, .btn-sm, .btn-primary, .form-control {
  border-radius: 5px;
  font-weight: bold;
}

.view-login {
  main {
    background-image: url("/images/amex/plane-passengers.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  #pv-content {
    margin-top: 1.5rem;

    .cb-login-module {
      max-width: 750px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0px;
      background-color: white;
      padding: 5px 0;
    }
  }
}

.view-userdetails {
  .cb-page-header-title {
    text-align: center;
  }
  #cbcheckedadminForm {
    .cbIconsTop {
      text-align: left;
    }
  }
}

.view-amex .list-group-item + .list-group-item {
  border-top-width: 1px;
}

.view-forms .create-form-card {
  height: calc(100% - 16px);
}

.white-text {
  color: white !important;
}



.amex-list {
.form-card-stats {
    overflow-x: scroll;
}

}
