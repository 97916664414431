@media print {
  body {
    padding: 0;
  }

  #system-message-container,
  #pv-skip-to {
    display: none;
  }

  body main {
    padding: 0;
  }

  header#pv-header {
    max-height: none;
  }

  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

}
